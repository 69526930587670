<template>
  <div class="page page-giftcode">
    <b-col class="py-3">
      <div class="d-flex justify-content-between">
        <Breadcrumb :items="[{label: $t('Giftcode')}]"/>
        <a href="#" class="codes-history" @click.prevent="$router.push({name: 'UseGiftHistory'})"><i class="fa-solid fa-clock-rotate-left"></i> {{$t("Used codes history")}}</a>
      </div> 
      <ButtonsTab :tabs="tabs" />
      <router-view />
    </b-col>
  </div>
</template>

<script>
import {redirectModes} from "@/contants";
import ButtonsTab from "@/components/tabs/ButtonsTab";

export default {
  name: "GiftCode",
  components: {ButtonsTab},
  data() {
    return {
      tabs: [{
        label: "All",
        mode: redirectModes.ROUTE_NAME,
        value: "GiftCodeIndex"
      }, {
        label: "Received",
        mode: redirectModes.ROUTE_NAME,
        value: "ReceivedGiftCodes"
      }, {
        label: "Use code",
        mode: redirectModes.ROUTE_NAME,
        value: "UseGiftCode"
      }]
    }
  }
}
</script>

<style scoped lang="scss">
.page-giftcode {
  .codes-history {
    color: #ee4623;
  }
}
</style>